import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);
/**
 * Return run advance width
 *
 * @param  {Object}  run
 * @return {number} advance width
 */


var advanceWidth = R.compose(R.reduce(R.useWith(R.add, [R.identity, R.propOr(0, "xAdvance")]), 0), R.propOr([], "positions"));
var _default = advanceWidth;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;